import React, { useEffect, useState } from 'react';
import { Button, message, Input, Checkbox,Modal,Tooltip } from 'antd';
import './Login.less';
import { UserOutlined, UnlockOutlined } from '@ant-design/icons';
import http from "../../utils/server";
import md5 from 'js-md5';
import { useHistory } from 'react-router-dom';
import { Mode } from '../../App';
import backgroundJpg from '../../../public/assets/backgroundMeiHao.jpg';
// import backgroundJpg from '../../../public/assets/backgroundCustomer.png';
// import backgroundJpg from '../../../public/assets/background.png';
import sizeMax1 from '../../../public/assets/sizemax1.png'
import sizeMax2 from '../../../public/assets/sizemax2.png'
import sizeMini from '../../../public/assets/sizemini.png'
import closeForm from '../../../public/assets/closeForm.png'
import loginpng from '../../../public/assets/loginMeiHao.png';
// import loginpng from '../../../public/assets/login.png';
function Login() {
    let history = useHistory()
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isSaveLogin,setIsSaveLogin] = useState(false);
    const [myConf,setMyConf] = useState({}); 
    const [modal, contextHolder] = Modal.useModal();
    const [isShowTitle,setIsShowTitle] = useState(false);//本地登录是否显示最大化最小化关闭
    const [isMax,setIsMax] = useState(false);
    const config = {
        title: '系统提示',
        content: (
        <>
            <div>端口打开失败</div>
        </>
        ),
    };
    let timerOut='';
    // let isSaveLogin = false;
    useEffect(()=>{
        if (window.CefSharp !== undefined) {
            setTimeout(() => {
                http.get('/s/getserver',{}).then(data=>{
                  if(data.code===0){
                    if(!data.data.PortStatus){
                        modal.error(config);
                        message.error("端口打开失败");
                    }
                  }
                })
              }, 60000);
            window.CefSharp.BindObjectAsync("bound");
            let myConf =window.bound.getConfig();
            setMyConf(myConf)
            setUsername(myConf.isSaveLogin?myConf.userLogin:'');
            setPassword(myConf.isSaveLogin?myConf.pwd:'');
            setIsSaveLogin(myConf.isSaveLogin);
            if (window.bound.getMaxWindow){
                setIsShowTitle(true)
                let isMax = window.bound.getMaxWindow();
                setIsMax(isMax);
            }
            // isSaveLogin = myConf.isSaveLogin;
        }
    },[])
    useEffect(()=>{
        timerOut = setTimeout(() => {
            if(isSaveLogin){
                clearTimeout(timerOut);
                loginIn(username,password,true);
            }
        }, 5000);
    },[isSaveLogin,username,password])

    function loginIn(username, password,isAutoLogin) {
        clearTimeout(timerOut);
        if (username === '' || password === '' || username === undefined) {
            message.error({ content: '信息不完善', key: '1' });
        } else {
            http.post("/s/login", { account: username, password: md5(password) }).then(data => {
                if (data.code === 0) {
                    if (window.CefSharp !== undefined) {
                        window.CefSharp.BindObjectAsync("bound");
                        isAutoLogin?window.bound.setLoginMode(true,username,password):
                        window.bound.setLoginMode(false,username,password);
                        window.bound.getIsLogin&&window.bound.getIsLogin(true);
                        window.bound.setToken(data.data.token)
                    }
                    localStorage.setItem("token", data.data.token);
                    localStorage.setItem("tokenis", true);
                    // localStorage.setItem("type_",data.data.type );
                    // localStorage.setItem("role",data.data.role );
                    // localStorage.setItem("user_", data.data.name);
                    // localStorage.setItem("account_", data.data.account);
                    localStorage.getItem('show')===null&&localStorage.setItem('show','0');
                    message.success("登录成功");
                    Mode ==='soc' ?
                    data.data.type===105?
                    history.push('/manage/alarmuser'):
                    history.push('/')
                    :
                    history.push('/monitor/data');
                } else {
                    message.error({ content: data.msg, key: '2' })
                    setIsSaveLogin(false)
                    // isSaveLogin = false;
                }
            })
        }
    }

    return (
        <div className="body1" style={{backgroundImage:`url(${backgroundJpg})`}}>
            {/* <div style={{height:100,padding:20}}>
                <div><span style={{fontSize:40,color:'#fff'}}>{Mode !== 'soc'?"自动化监测软件":'温湿度监测云平台'}</span></div>
                <div style={{paddingLeft:80}}><span style={{fontSize:30,color:'#fff'}}>专业、专注</span></div>
            </div> */}
           
            {

                isShowTitle&&
                <div className='localTitle'>
                    <div style={{width:40}}></div>
                    <Tooltip title={'最小化'}>
                        {/* <span style={{fontSize:24,fontWeight:'bold'}}>_</span> */}
                        <img src={sizeMini} alt="" srcset=""  style={{height:24}} onClick={e=>{
                            window.CefSharp.BindObjectAsync("bound");
                            window.bound.minWindow()
                        }}/>
                    </Tooltip>
                    <Tooltip title={isMax?'恢复':'最大化'}>
                        <img src={isMax?sizeMax1:sizeMax2} alt="" srcset=""  style={{height:24}} onClick={e=>{
                            window.CefSharp.BindObjectAsync("bound");
                            window.bound.setMaxWindow()
                            setIsMax(!isMax)
                        }}/>
                    </Tooltip>
                    <Tooltip title={'关闭'}>
                        <img src={closeForm} alt="" srcset=""  style={{height:24}} onClick={e=>{
                            window.CefSharp.BindObjectAsync("bound");
                            window.bound.closeWindow()
                        }}/> 
                    </Tooltip>
                </div>
            }
            {/* <div className='loginContain'> */}
            <div className='loginContainMeiHao'>
                <img className='loginImgMeiHao' src={loginpng} alt="登录" />
                {/* <img className='loginImg' src={loginpng} alt="登录" /> */}
                {/* <div></div> */}
                <div className='loginBoxMeiHao' >
                {/* <div className='loginBox' > */}
                    <div style={{ fontSize:'3vw' , fontWeight: 'bold',marginTop:'4vh',textAlign:'center'}}>欢迎登录</div>
                    {/* {Mode==='soc'&&<div ><a href="http://trs.hbpckj.com">订单查询系统</a></div>} */}
                    
                    <Input style={{marginTop:'4vh',borderRadius:10}}
                        size={'large'}
                        prefix={<UserOutlined />}
                        placeholder='用户名'
                        value={username}
                        onChange={(e) => { setUsername(e.target.value) }}
                        autoFocus={true}
                        onPressEnter={(e)=>{
                            loginIn(document.getElementsByTagName('input')[0].value, document.getElementsByTagName('input')[1].value,isSaveLogin?true:false);
                        }}
                    />

                    <Input style={{marginTop:'3vh',borderRadius:10}}
                        size={'large'}
                        prefix={<UnlockOutlined />}
                        placeholder='密码'
                        value={password}
                        type='password'
                        onChange={(e) => setPassword(e.target.value)}
                        onPressEnter={(e)=>{
                            loginIn(document.getElementsByTagName('input')[0].value, document.getElementsByTagName('input')[1].value,isSaveLogin?true:false);
                         }}
                    />
                    
                    {
                        (Mode!=='soc'&&myConf&&myConf.isShowLoginAuto)&&
                        <div style={{marginTop:'3vh'}}>
                            <Checkbox  checked={isSaveLogin} onChange={e=>{
                                clearTimeout(timerOut)
                                let temp = e.target.checked;
                                setIsSaveLogin(temp);
                                if (window.CefSharp !== undefined ) {
                                    if(temp){
                                        timerOut=setTimeout(() => {
                                            loginIn(username, password,true)
                                        }, 2000);
                                    }else{
                                        window.bound.setLoginMode(false,username,password);
                                    }
                                }
                            }}>自动登录</Checkbox>
                        </div>
                    }
                    <Button style={{marginTop:'4vh',borderRadius:10}} size={'large'} type="primary" block onClick={() => loginIn(username, password,isSaveLogin?true:false)}>登录</Button>
                    <div style={{ fontSize:'1.5vh',marginTop:'4vh' }}>{Mode !== 'soc'?"自动化监测软件":'监测云平台 v_3.0'}</div>
                </div>
            </div>
        </div>
    )
}
export default Login;