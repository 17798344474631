import React, { useState, useContext, useEffect } from 'react'
import {  StateContext } from '../../../state';
import { StateDeviceContext } from '../../../state_device';
import http from '../../../../../utils/server'
import { Select, Tree,  Button, Switch, Input, message, Spin, List, Checkbox, Tooltip,Modal,DatePicker,Tabs, Radio} from 'antd'
import { AppstoreOutlined, MenuOutlined, SyncOutlined, DoubleLeftOutlined, DoubleRightOutlined,  DownOutlined,UpOutlined, LayoutOutlined } from '@ant-design/icons';
import '../../../../../../node_modules/react-simple-tree-menu/dist/main.css';
import DeviceItem from './device';
import Device902Item from './device902';
import UpDevManage from './upDev';
import Plane from './plane';
import { Link,} from 'react-router-dom';
import { Mode } from '../../../../../App';
import audioSrc from '../../../../../../public/assets/warning.mp3'
import moment from 'moment';
import './index.less'

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
let interval = '';
let timer='';
let showDiv;
let showDivH;
let audioEle;
let beforeScroll=0;
let dateTimer ='';
function formatDateTimes(inputTime) {
  var date = new Date(inputTime);
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? ('0' + m) : m;
  var d = date.getDate();
  d = d < 10 ? ('0' + d) : d;
  var h = date.getHours();
  h = h < 10 ? ('0' + h) : h;
  var minute = date.getMinutes();
  var second = date.getSeconds();
  minute = minute < 10 ? ('0' + minute) : minute;
  second = second < 10 ? ('0' + second) : second;
  return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
};
function Data() {
  const { state, dispatch } = useContext(StateContext)
  const { state_device, dispatch_device } = useContext(StateDeviceContext)
  const [showArea, setShowArea] = useState([])//展示的区域列表
  const [showDef, setShowDef] = useState([])//所有区域列表
  const [show, setShow] = useState(2);//区域
  const [showtree, setShowTree] = useState(true)
  // const [showOnline, setShowOnline] = useState(false);
  const [showOff, setShowOff] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [expendKey, setExpendKey] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState([]);
  const [selKey, setSelKey] = useState([]);
  const [planeVis,setPlaneVis] = useState(false);

  const [closeInterval, setCloseInterval] = useState(false)
  const [showData, setShowData] = useState([])//条件查找设备存储数组
  const [showDataF, setShowDataF] = useState([])//设备存储数组
  const [showDis, setShowDis] = useState([])//筛选不在线设备的区域存储数组
  const [defExpend,setDefExpend]=useState([]);//树形结构默认展开节点
  const [showTreeDis,setShowTreeDis] = useState([]);//树形结构选择用户下区域
  const [showUpData,setShowUpData] = useState(false);//本地是否显示上传追溯系统按钮
  const [showUpDataModal ,setShowUpDataModal] = useState(false);//本地上传追溯系统弹窗界面
  const [startTime, setStartTime] = useState('');//本地上传追溯系统开始时间
  const [endTime, setEndTime] = useState('')//本地上传追溯系统结束时间
  const [storeDevOptions,setStoreDevOptions] = useState([]);//本地上传追溯系统仓库设备
  const [incDevOptions,setIncDevOptions] = useState([]);//本地上传追溯系统保温箱设备
  const [vehDevOptions,setVehDevOptions] = useState([]);//本地上传追溯系统车载设备
  const [devTypeOptions,setDevTypeOptions] = useState([]);//本地上传设备类型选择
  const [selDevType,setSelDevType] = useState(0);//选中的本地上传设备类型
  const [storeIndeterminate, setStoreIndeterminate] = useState(false); //本地上传追溯系统仓库设备全选
  const [incIndeterminate, setIncIndeterminate] = useState(false); //本地上传追溯系统保温箱设备全选
  const [vehIndeterminate, setVehIndeterminate] = useState(false); //本地上传追溯系统车载设备全选
  const [storeCheckAll, setStoreCheckAll] = useState(true);//本地上传追溯系统仓库设备设置全选
  const [incCheckAll, setIncCheckAll] = useState(true);//本地上传追溯系统保温箱设备设置全选
  const [vehCheckAll, setVehCheckAll] = useState(true);//本地上传追溯系统车载设备设置全选
  const [storeChecked,setStoreChecked] = useState([]);//本地上传追溯系统仓库设备已选择
  const [incChecked,setIncChecked] = useState([]);//本地上传追溯系统保温箱设备已选择
  const [vehChecked,setVehChecked] = useState([]);//本地上传追溯系统车载设备已选择
  const [storeDevValues,setStoreDevValues] = useState([]);//本地上传追溯系统仓库设备value值
  const [incDevValues,setIncDevValues] = useState([]);//本地上传追溯系统保温箱设备value值
  const [vehDevValues,setVehDevValues] = useState([]);//本地上传追溯系统车载设备value值
  const [dates, setDates] = useState([]);//本地上传追溯系统日期选择待选时间值
  const [upQYID,setUpQYID] = useState('');//本地上传追溯系统企业ID
  const [upKEY,setUpKEY] = useState('');//本地上传追溯系统秘钥
  const [upAuto,setUpAuto] = useState(false);//本地自动上传开关
  const [isUpHuBei,setIsUpHuBei] = useState(false);//本地开关
  const [devUpHuBei,setDevUpHuBei] = useState([]);//设备
  const [upAutoCar,setUpAutoCar] = useState(false);//本地自动上传开关
  const [upAutoWare,setUpAutoWare] = useState(false);//本地自动上传开关
  const { Search } = Input;

  const [isAlarm,SetIsAlarm] = useState(false)
  
  function sortBy(data) {
    data = data.filter(x=>x.Sensor.Hide!==1);
    data.sort((a,b)=>{
      if(!a.Online&&b.Online) return 1;
      if(a.Online&&!b.Online) return -1;
      let d = a.MName.localeCompare(b.MName,"zh-Hans-CN")
      if(d!==0) return d;
      if(a.Sn>b.Sn) return 1;
      if(a.Sn<b.Sn) return -1;
      if(a.Node===0&&a.Node<b.Node)return 1
      if(b.Node===0&&a.Node>b.Node)return -1
      if(a.Node>b.Node) return 1;
      if(a.Node<b.Node) return -1;
    })
    return data
  }

  function getAlarmData(){
    let  alarmSound = localStorage.getItem('alarmSoundChange')==='true'?true:false
    console.log('warn',isAlarm,alarmSound,audioEle);
    isAlarm &&alarmSound&&audioEle.play();
    let demo = state_device.devicestate.filter(x=>x.Type===213)
    if(demo&&demo.length>0&& isAlarm &&alarmSound){
      http.post((Mode==='soc' ? "/v1" : "/v3") + "/senddevice", {device: demo[0].Sn,msg:'5A0106000100051809A5' }).then(data => {
      })
    }
  }
  function getUpDevs(temp){
    let storeDevs = temp.filter(x=>x.type===201);//仓库设备
    let vehDevs = temp.filter(x=>x.type===203);//车载
    let incDevs = temp.filter(x=>x.type===202||x.type===209);//保温箱
    let storeDevOptions =[];
    let vehDevOptions =[];
    let incDevOptions =[];
    let storeValues =[];
    let incValues =[];
    let vehValues =[];
    let storeValChecked =[];
    let incValChecked =[];
    let vehValChecked =[];
    storeDevs.map(m=>{
      storeDevOptions.push({label:m.m_name+'——'+m.name,value:m.id});
      storeValues.push(m.id);
      if(m.upSn!==""&&m.upSn!=="false"&&m.upSn!=="0"){
        storeValChecked.push(m.id);
      }
    })
    incDevs.map(m=>{
      incDevOptions.push({label:m.m_name+'——'+m.name,value:m.id});
      incValues.push(m.id);
      if(m.upSn!==""&&m.upSn!=="false"&&m.upSn!=="0"){
        incValChecked.push(m.id);
      }
    })
    vehDevs.map(m=>{
      vehDevOptions.push({label:m.m_name+'——'+m.name,value:m.id});
      vehValues.push(m.id);
      if(m.upSn!==""&&m.upSn!=="false"&&m.upSn!=="0"){
        vehValChecked.push(m.id);
      }
    })
    setIncDevOptions(incDevOptions);
    setVehDevOptions(vehDevOptions);
    setStoreDevOptions(storeDevOptions);
    setStoreDevValues(storeValues);
    setIncDevValues(incValues);
    setVehDevValues(vehValues);
    setStoreChecked(storeValChecked);
    setIncChecked(incValChecked);
    setVehChecked(vehValChecked);
    setIncCheckAll(incValChecked.length===incValues.length?true:false)
    setVehCheckAll(vehValChecked.length===vehValues.length?true:false)
    setStoreCheckAll(storeValChecked.length===storeValues.length?true:false)
    let typeRadioOptions = [];
    storeDevs.length>0&& typeRadioOptions.push({label:'库房设备',value:1}); 
    incDevs.length>0&& typeRadioOptions.push({label:'保温箱设备',value:2}); 
    vehDevs.length>0&& typeRadioOptions.push({label:'车载设备',value:3}); 
    setDevTypeOptions(typeRadioOptions);
    setSelDevType(typeRadioOptions.length>0&&typeRadioOptions[0].value);
  }
  useEffect(async () => {
    let confJson = false
    if (window.CefSharp !== undefined) {
      window.CefSharp.BindObjectAsync("bound");
      let myConf=window.bound.getConfig();
      setShowUpData((myConf.isUpDataHeB||myConf.isUpDataHuBei)?true:false);
      setUpQYID(myConf.upQYID);
      setUpKEY(myConf.upKEY);
      setUpAuto(myConf.upAuto);
      setIsUpHuBei(myConf.isUpDataHuBei)
      setUpAutoCar(myConf.upAutoCar)
      setUpAutoWare(myConf.upAutoWare)
      if (myConf.isUpDataHuBei){
        confJson = JSON.parse(myConf.confJsonDevs);
        if(confJson&&confJson.Device&&confJson.Device.length>0){
          setDevUpHuBei(confJson.Device)
        }else{
          setDevUpHuBei([])
        }
      }
    }
    audioEle = document.getElementById('audio');
    setIsLoading(true);
    let showMode = localStorage.getItem('show') * 1
    if(showMode==2){
      setShowOff(()=>true);
    }
    setShow(showMode);
    let loginUser;
    let userData;
    let dOn;
    http.get("/v1/device", {}).then(data => {
      if (data.code === 0) {
        dispatch({ type: 'device', device: data.data })
        if(data.data!==null&&data.data.length>0){
          let temp = data.data.filter(x=>x.node!==0&&x.upSn!==''&&x.upSn!=="0");
          if(confJson&&confJson.Device){

            temp =data.data.filter(x=> confJson.Device.some(y=>y.Device===x.sn &&y.Node===x.node))
          }
          getUpDevs(temp);
        }
      }
    })
    await http.get("/v1/users", {}).then(data => {
      if (data.code === 0) {
        if (data.data.length > 0) loginUser = data.data[0];
        let oData = data.data.sort((a,b)=>a.name.localeCompare(b.name,"zh"))
        dispatch({ type: 'systemuser', systemuser: oData })
        userData = oData;
        var list = [];
        userData.filter(x=>x.parent===loginUser.id).map(m=>list.push(m.id+""));
        setDefExpend(list);
      }
    })
    http.get("/v1/userdistricts", {}).then(data => {
      if (data.code === 0) {
        dispatch({ type: 'user_district', user_district: data.data });
      }
    })
    await http.get("/v1/district", {}).then(data => {
      if (data.code === 0) {
        dispatch({ type: 'district', district: data.data });
        var dsl = new Array();
        let temshowDis = [];
        if(Mode==='soc'){
          if (loginUser.type === 104) {
            data.data.map(item => {
              temshowDis.push({id:item.id,showUnLine:showMode==2?true:false});
              dsl.push({ id: item.id, name: item.name, user: '', parentUser: loginUser.title });
            })
          } else {
            let userTree = new Array();
            
            if (loginUser.type === 101) {
              findParentuser(0, userData, userTree);
            } else{
              userTree.push({key:loginUser.id,name:loginUser.name,type:loginUser.type,id:loginUser.id,children:[]});
              findParentuser(loginUser.id,userData,userTree);
            }
            let userlist = new Array();
            treeToList(userTree,userlist);
            
            userlist.map(m=>{
              data.data.filter(x=>x.user===m.id).map(item => {
                temshowDis.push({id:item.id,showUnLine:showMode==2?true:false});
                if (item.user === loginUser.id) {
                  dsl.push({ id: item.id, name: item.name, user: m.title });
                } else {
                  let parentName = new Array();
                  getparent(userData, item.user, parentName);
                  let str = '';
                  for (let i = parentName.length - 2; i > 0; i--) {
                    str += parentName[i] + '>'
                  }
                  str += parentName[0]
                  dsl.push({ id: item.id, name: item.name, user: m.title, parentUser: str });
                }
              })
            })
          }
        }else{
            dsl = data.data;
            data.data.map(item=>temshowDis.push({id:item.id,showUnLine:showMode==2?true:false}));
        }
        setShowArea(dsl.slice(0,15));
        setShowDef(dsl);
        if(temshowDis.length>0){
          setShowDis(temshowDis);
        }
        setIsLoading(false);
      }
    })
    http.get("/v1/devicestatus", {}).then(data => {
      if (data.code === 0) {
        if (data.data === null) { setCloseInterval(true) }
        else {
          let d = sortBy(data.data);
          let alarmdata= d.find(x=>x.Status.PowerStatus===1||x.Sensor.TempAlarm||x.Sensor.HumiAlarm)
          SetIsAlarm(alarmdata==undefined?false:true)
          setCloseInterval(false);
          dispatch_device({ type: 'devicestate', devicestate: d });
          if(showMode==2){
            setShowDataF(d);
          }else{
            dOn =  d.filter(x=>x.Online);
            setShowDataF(dOn);
          }
        }
      }
    })
    http.get("/v1/layout", {}).then(data => {
      if (data.code === 0) {
          dispatch({ type: 'hmi', hmi: data.data })
      }
    })
    getAlarmData()
    
    if(Mode==='soc'){
      if(state.main.type<102){
        dispatch({ type: 'setState', setState: 2 });
        dispatch({type:'super',super:2})
      }else{
        dispatch({ type: 'setState', setState: 0 });
        dispatch({type:'super',super:0})
      }
    }
  }, [])

  function findParentuser(nop, list, child) {
    if (list) {
        const nops = list.filter(x => { return x.parent === nop })
        if (nops.length === 0) {
            return
        } else {
            for (var j = 0; j < nops.length; j++) {
                var u = new Object
                u.key = nops[j].id
                u.name = nops[j].name
                u.type = nops[j].type
                u.parent = nops[j].parent
                u.id = nops[j].id
                u.children = new Array()
                child.push(u);
                findParentuser(nops[j].id, list, u.children)
            }
        }
      }
  }

  function treeToList(tree,list) {
    tree.map(t=>{
      list.push(t);
      t.children!==undefined&&treeToList(t.children,list);
    })
  }
  function getparent(arr, pid, name) {
    let p = arr.filter(ar => ar.id === pid);
    if(p.length>0){
      name.push(p[0].title);
      p[0].type !== 101 && getparent(arr, p[0].parent, name);
    }
  }
  // 定时语音报警
  useEffect(()=>{
    clearInterval(timer);
    timer=setInterval(() => {
      getAlarmData();
    }, 60*1000);
    return ()=>clearInterval(timer);
  },[])
  // 定时刷新数据
  useEffect(() => {
    interval = setInterval(() => {
      if (!closeInterval) {
        http.get("/v1/devicestatus", {}).then(data => {
          if (data.code === 0 && data.data != null) {
            // let dataCon = data.data.filter(x=>!x.Online || new Date(x.Status.LastMsgTime).getTime>timeBase || timeBase-new Date(x.Status.LastMsgTime).getTime()<1000*60*4)
            let dataCon = data.data;
            let alarmdata= dataCon.find(x=>x.Online&&((x.Status.PowerStatus===1&&x.Node===0)||x.Sensor.TempAlarm||x.Sensor.HumiAlarm))
            SetIsAlarm(alarmdata===undefined?false:true)
            let smokeDevStoList = JSON.parse(localStorage.getItem('smokeDev'));
            if(!smokeDevStoList){
              smokeDevStoList=[];
            }
            let smokeAlarmList = dataCon.filter(x=>x.Type===207&&x.Sensor.Smoke);
            if(smokeAlarmList&&smokeAlarmList.length>0){
              smokeAlarmList.map(item=>{
                if(smokeDevStoList&&!smokeDevStoList.includes(item.Sn)){
                  smokeDevStoList.push(item.Sn)
                  if(window.CefSharp!==undefined){
                    window.CefSharp.BindObjectAsync("bound");
                    window.bound.socketSendAlarm(''+item.Sn,0);
                  }
                }
              })
            }
            localStorage.setItem("smokeDev", JSON.stringify(smokeDevStoList));
            let d = sortBy(dataCon);
            // let dOn =  d.filter(x=>x.Online);
            let showD = [];
            dispatch_device({ type: 'devicestate', devicestate: d });
            //条件查询设备数据更新
            if(showData.length>0){
              showData.map(m=>showD=showD.concat(d.filter(x=>x.ID===m.ID)));
              setShowData(showD);
            }else{
              if(showDis.length>0){
                showDis.map(item=>item.showUnLine?showD=showD.concat(d.filter(x=>x.District===item.id)):showD=showD.concat(d.filter(x=>x.District===item.id&&x.Online)))
                setShowDataF(showD);
              }else{
                setShowDataF([])
              }
            }
          }
        })
      } else {
        clearInterval(interval)
      }
    }, 20000)
    return () => clearInterval(interval)
  }, [closeInterval,showDis,showData,showOff])

  function autoRefresh(checked) {
    if (checked) {
      setCloseInterval(false)
    } else {
      setCloseInterval(true)
    }
  }

  function getDevicestate() {
    http.get("/v1/devicestatus", {}).then(data => {
      if (data.code === 0) {
        if(data.data !== null){
          let d = sortBy(data.data);
          let showD = [];
          dispatch_device({ type: 'devicestate', devicestate: d});
          if(showDis.length>0){
            showDis.map(item=>item.showUnLine?showD=showD.concat(d.filter(x=>x.District===item.id)):showD=showD.concat(d.filter(x=>x.District===item.id&&x.Online)));
            setShowDataF(showD);
          }
        }   
      }
    })
  }

  function treeOnChange(e) {
    const { value } = e.target;
    let temp= state.systemuser.list.filter(item=>item.name.indexOf(value)>-1);
    if(temp.length>0){
      let eKey =[];
      temp.map(item=>eKey.push(item.id))
      setExpendKey(eKey);
      setAutoExpandParent(true)
    }
    const keys = document.querySelectorAll('.draggable-tree span[title]');
    for (var i = 0; i < keys.length; i++) {
      if (value !== '') {
        if (keys[i].title.indexOf(value) > -1) {
          keys[i].style.color = '#f50';
        } else {
          keys[i].style.color = '#262626';
        }
      } else { keys[i].style.color = '#262626'; }
    }
  };

  return (
    <>
      <div style={{ display: 'flex', height: '100%' }} id='DataView'>
      <audio id="audio"  src={audioSrc} />
        {/* 左侧树导航开始 */}
        {Mode==='soc'?state.main.type === 104 ? null :
          <div id='tree' style={{ width: 200,borderRight: '1px solid #fcd', display: 'none',overflow:'scroll'}}>
            <Search style={{ marginBottom: 8 }} placeholder="查找" onChange={treeOnChange} />
            {state.systemuser.tree.length > 0 &&
              <Tree
                className="draggable-tree"
                titleRender={nodeDate=><span style={{whiteSpace:'nowrap',display:'inline-block'}}>{nodeDate.title}</span>}
                defaultExpandedKeys={defExpend}
                blockNode
                showLine
                switcherIcon={<DownOutlined />}
                expandedKeys={expendKey}
                onExpand={
                  expandedKeys => {
                    setExpendKey(expandedKeys);
                    setAutoExpandParent(false);
                  }
                }
                autoExpandParent={autoExpandParent}
                showIcon
                treeData={state.systemuser.treeM}
                onSelect={(key, e) => {
                  setSelKey(key);
                  const us = state.systemuser.list.filter(x => { return x.id == key[0] })//获取单击的用户
                  if (us.length > 0) {
                    const u = us[0];
                    var dsl = new Array();
                    var dis =[];
                    //获取创建的用户名
                    let parentName = new Array();
                    getparent(state.systemuser.list, u.id, parentName);
                    let str = '';
                    for (let i = parentName.length - 1; i > 0; i--) {
                      str += parentName[i] + '>'
                    }
                    str += parentName[0];

                    //不同用户获取用户下所有的区域
                    if (u.type === 104) {
                      const ds = state.user_district.filter(x => { return x.user === u.id });
                      if (ds.length == 0) return message.warn('暂无数据');
                      ds.map(item => {
                        state.district.map(m => {
                          if (m.id == item.district) {
                            state.systemuser.list.map(l => {
                              if (l.id == u.parent) {
                                dsl.push({ id: item.district, name: m.name, user: l.title, parentUser: str })
                                dis.push({id:item.district,showUnLine:false});
                              }
                            })
                          }
                        })
                      })
                    }
                    else {
                      let userTree = new Array();
                      if (u.type === 101) {
                        setSelKey([])
                        findParentuser(0, state.systemuser.list, userTree);
                      } else{
                        userTree.push({key:u.id,name:u.name,type:u.type,id:u.id,children:[]});
                        findParentuser(u.id,state.systemuser.list,userTree);
                      }
                      let userlist = new Array();
                      treeToList(userTree,userlist);
                      userlist.map(m=>{
                        state.district.filter(x=>x.user===m.id).map(item => {
                          let parentName = new Array();
                          getparent(state.systemuser.list, item.user, parentName);
                          let str = '';
                          for (let i = parentName.length - 2; i > 0; i--) {
                            str += parentName[i] + '>'
                          }
                          str += parentName[0];
                          dsl.push({ id: item.id, name: item.name, user: m.title, parentUser: str });
                          dis.push( {id:item.id,showUnLine:false});
                        })
                      })
                    }
                    if (dsl.length === 0) return message.warn('暂无数据');
                    setShowTreeDis(dsl);
                    dsl.length > 12?setShowArea(dsl.slice(0,15)):setShowArea(dsl)
                    setShowDis(dis);
                    let showD = [];
                    dis.map(item=>item.showUnLine?showD=showD.concat(state_device.devicestate.filter(x=>x.District===item.id)):showD=showD.concat(state_device.devicestate.filter(x=>x.District===item.id&&x.Online)))
                    setShowDataF(showD);
                    // setIsLoading(false);
                  }
                }}
              /> }
          </div>:
          // 本地端树列表
          <div id='tree' style={{ width: 200, height: '100%', borderRight: '1px solid #fcd', display: 'none' }}>
            {
              state.district.length>0&&<List
              header={<div style={{paddingLeft:15,paddingTop:10}}>选择区域</div>}
              dataSource={state.district}
              renderItem={item=>(
                <div style={{paddingLeft:15,paddingTop:10}} ><Checkbox  value={item.id} defaultChecked onChange={e=>{
                  e.target.checked?setShowArea(showArea.concat({id:item.id,name:item.name})):
                  setShowArea(showArea.filter(x=>x.id!==item.id));
                  setIsLoading(false);
                }}>{item.name}</Checkbox></div>
                )}
            />
            }
          </div>
        }
        {/* 左侧树导航结束 */}

        {/* 右侧显示内容开始 */}
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column'}}>
          {/* 右侧显示内容头部 */}
          <div style={{ marginBottom: 12, display: 'inline-flex', display: '-webkit-inline-flex', alignItems: 'center', padding: 10, boxShadow: '6px 6px 0 #ccc', borderRadius: 10,backgroundColor:'#fff' }}>
            <div style={{ width: 200, flex: 1 }}>

              {state.main.type === 104 ? null : <Button type="primary" icon={showtree ? <DoubleRightOutlined /> : <DoubleLeftOutlined />} onClick={() => {
                showtree ? document.getElementById('tree').style.display = 'block' : document.getElementById('tree').style.display = 'none'
                setShowTree(!showtree)
              }} size='middle' />}

              {/* <Tooltip title='切换视图'> */}
                  <Button type="primary" style={{ marginLeft: 5 }} icon={show === 0 ? <MenuOutlined /> : <AppstoreOutlined />} onClick={() => {
                    setShowOff(false);
                    setShowDis(
                      showDis.map(item=>({
                        ...item,
                        showUnLine:false
                      })) 
                    )
                    setShowDataF(state_device.devicestate.filter(x=>x.Online))
                    if (show === 0) {
                      setShow(1)
                      localStorage.setItem("show", 1 + '')
                    } else {
                      setShow(0)
                      localStorage.setItem("show", 0 + '')
                    }
                  }} size='middle' />
                  
                  <Button type="primary" style={{ marginLeft: 5,paddingLeft:8,paddingRight:8}} onClick={() => {
                    setShowOff(true);
                    setShowDis(
                      showDis.map(item=>({
                        ...item,
                        showUnLine:true
                      })) 
                      )
                      setShowDataF(state_device.devicestate)
                    setShow(2)
                    localStorage.setItem("show", 2 + '')
                  }} size='middle' >
                  简  
                  </Button>

              {/* </Tooltip> */}
              <Tooltip title='查找设备'>
                <Search allowClear  placeholder="请输入设备信息" enterButton onSearch={value => {
                  setIsLoading(true);
                  // setShowDataValue(value);
                  if (value == "") {
                    return (
                      setShowArea(showArea),
                      setShowData([]),
                      setIsLoading(false)
                    )
                  }
                  let arr = state_device.devicestate.filter(item => { return (item.Sn + '').indexOf(value) > -1 | item.Name.indexOf(value) > -1 | (item.Node + '').indexOf(value) > -1 });
                  if (arr.length == 0) {
                    message.error('没有该条件设备信息!!');
                    setShowArea(showArea);
                    setShowData([]);
                    setIsLoading(false);
                  } else {
                    setShowData(arr);
                    var dsl = new Array();
                    state.district.map(m => {
                      arr.filter((ar, index, self) =>
                        index === self.findIndex(it => it.District === ar.District)).map(ar => {
                          if (ar.District === m.id) {
                            let parentName = new Array();
                            getparent(state.systemuser.list, m.user, parentName);
                            let str = '';
                            for (let i = parentName.length - 1; i > 0; i--) {
                              str += parentName[i] + '>'
                            }
                            str += parentName[0]
                            dsl.push({ id: m.id, name: m.name, user: m.user_name, parentUser: str });
                          }
                        })
                    })
                    setShowArea(dsl);
                    setIsLoading(false);
                  }

                }} style={{ width: 200, marginLeft: 20 }} />
              </Tooltip>
              
            </div>
            {
              showUpData&&
              <Button type="primary" style={{borderRadius:10,marginRight:10}}
              onClick={()=>setShowUpDataModal(true)}>
                上传追溯
              </Button>
            }
            {/* <Button onClick={()=>{
              let devList = state_device.devicestate.filter(x=>x.Node===1&&x.Online).sort((a,b)=>b.Sn-a.Sn);
              let count = devList.length;
              console.log('主机列表和数量',count,devList);
              let num = 0
              let tInterval= setInterval(() => {
                if(num<count){
                  http.post("/v1/readdevicedata", {starttime	:"2024-06-21 00:30", endtime:'2024-06-21 07:00', device: devList[num].Sn, node: devList[num].Node })
                  num++;
                }else{
                  clearInterval(tInterval)
                }
              }, 5000);
            }}>全部读取数据</Button> */}
            <div style={{width:100}}>
              <Button  type="text" shape='circle'
              icon={showDis.length===0?<DownOutlined style={{fontSize:16,color:'#1890ff'}}/>:<UpOutlined style={{fontSize:16,color:'#1890ff'}}/>}
              onClick={()=>{
                setIsLoading(true);
                let tempDis=[];
                showDis.length!==0?tempDis=[]:
                state.district.map(item=>tempDis.push({id:item.id,showUnLine:showOff?true:false}));
                setShowDis(tempDis);
                if( tempDis.length>0){
                  let d =  state_device.devicestate;
                //   let showD =[];
                // showDis.map(item=>item.showUnLine?showD=showD.concat(d.filter(x=>x.District===item.id)):showD=showD.concat(d.filter(x=>x.District===item.id&&x.Online)));
                 setShowDataF(showOff?d:d.filter(x=>x.Online));
                 setIsLoading(false);
                }else{
                  setShowDataF([]);
                  setIsLoading(false);
                }
              }}
              >
                {showDis.length===0?'隐藏区域':'显示区域'}
              </Button>
            </div>
            <div style={{ width: 150 }}>
              {/* 整体隐藏离线设备开关 */}
              <Checkbox onChange={e=>{
                if(e.target.checked){
                  setShowOff(true);
                  setShowDis(
                    showDis.map(item=>({
                      ...item,
                      showUnLine:true
                    })) 
                    )
                  setShowDataF(state_device.devicestate)
                }else{
                  setShowOff(false);
                  setShowDis(
                    showDis.map(item=>({
                      ...item,
                      showUnLine:false
                    }))
                  )
                  setShowDataF(state_device.devicestate.filter(x=>x.Online))
                }
              }} disabled={show==2}>
                显示全部设备
              </Checkbox>
            </div>
            <div style={{ width: 200 }}>
              <span>自动刷新:</span><Switch style={{ marginLeft: 10 }} checkedChildren="开启" unCheckedChildren="关闭" defaultChecked onChange={autoRefresh} />
              <Tooltip title='刷新'><Button style={{ marginLeft: 30 }} shape="circle" icon={<SyncOutlined style={{ fontSize: '20px' }} onClick={getDevicestate} />} /></Tooltip>
            </div>

          </div>
          {/* 右侧显示内容主体 */}
          {state_device.devicestate.length > 0 && 
          <div id = 'showD' style={{ width: '100%',overflowY:'scroll'}} 
          onScroll={()=>{
            // 没有查询内容
            if(showData.length===0){
              showDiv = document.getElementById('showD');
              showDivH =showDiv.offsetHeight;
              
              let showDivHB = showDiv.scrollHeight-showDivH-showDiv.scrollTop;
              let dire= showDiv.scrollTop-beforeScroll;//方向 向上滚动还是向下滚动
              //滚动加载
              if(selKey.length===0&&showDivHB<60&&dire>0){
                if(showArea.length<=showDef.length-10){
                  setShowArea(showDef.slice(0,showArea.length+10)); 
                }if(showArea.length>showDef.length-10&&showArea.length<showDef.length){
                  setShowArea(showDef); 
                }               
              }
              if(selKey.length!==0&&showDivHB<60&&dire>0){
                if(showArea.length<=showTreeDis.length-10){
                  setShowArea(showTreeDis.slice(0,showArea.length+10)); 
                }if(showArea.length>showTreeDis.length-10&&showArea.length<showTreeDis.length){
                  setShowArea(showTreeDis); 
                }               
              }
              if(selKey.length===0&&showDiv.scrollTop===0){
                setShowArea(showDef.slice(0,15));
              }
              if(selKey.length!==0&&showDiv.scrollTop===0){
                setShowArea(showTreeDis.slice(0,15));
              }
              beforeScroll = showDiv.scrollTop
            }
          }}
          >
              <Spin spinning={isLoading} size='large' style={{ marginTop: 200 }}>
                {           
                  showArea.map(m => {
                    return <div key={m.id} style={{ margin: 5, borderRadius: 10 ,border:'1px solid #ccc',backgroundColor:'#EBE9E7'}}>
                        <div style={{ display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',color:'#000',padding:'10px',borderRadius:10,backgroundColor:'#F4F5FA'}}>
                          <div>
                            {Mode==='soc'&& m.user !== '' &&<span>{m.parentUser}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                            <span>区域 : &nbsp;&nbsp;{m.name}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span>测点数量 : &nbsp;&nbsp;{state.device.filter(x => { return x.district === m.id &&x.node!==0&&x.hide!==1}).length}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span> 在线 : &nbsp;&nbsp;{state_device.devicestate.filter(x => { return x.District === m.id&&x.Online&&x.Node!==0&&x.Sensor.Hide!==1 }).length}</span>
                          </div>
                          <div style={{display:'flex',flexDirection:'row'}}>
                            {/* <Button type='text' onClick={()=>setPlaneVis(true)}
                            >平面图</Button>               */}
                            {
                              state.hmi.findIndex(x=>x.district===m.id)!==-1&&
                              <Link to={'/monitor/data/plane/'+m.id}>
                                <LayoutOutlined  style={{color:'#000',fontSize:18}}/>
                              </Link>
                            }
                            {/* <Switch><Route path={'/monitor/data/plane'} component={Plane}></Route></Switch> */}
                            <div  style={{margin:'0px 10px'}}
                            // ghost shape='circle'  size
                            onClick={()=>{
                              let temp = showDis;
                              if(showDis.filter(x=>x.id === m.id).length===0){
                                temp.push({id:m.id,showUnLine:showOff?true:false});
                                setShowDataF(showDataF.concat(state_device.devicestate.filter(x=>x.District===m.id&&x.Online)));
                              }else{
                                temp.splice(temp.findIndex(x=>x.id===m.id),1);
                                setShowDataF(showDataF.filter(x=>x.District!==m.id));
                              }
                              setShowDis(temp);

                            }}>
                              {showDis.filter(x=>x.id === m.id).length===0?
                              <div>
                                <span>展开 </span>
                                <DownOutlined style={{fontSize:16,color:'#000'}}/>
                              </div> :
                              <div>
                                <span>收起 </span>
                                <UpOutlined style={{fontSize:16,color:'#000'}}/>
                              </div>
                              }
                            </div>
                            <Checkbox 
                            disabled={showDis.filter(x=>x.id===m.id).length===0}
                            checked={showDis.filter(x=>x.id===m.id).length>0&&showDis.filter(x=>x.id===m.id)[0].showUnLine} 
                            onChange={e=>{
                              let temp=[]
                              if(e.target.checked){
                                showDis.map(item=>{
                                  item.id===m.id?
                                  temp.push({id:item.id,showUnLine:true})
                                  :
                                  temp.push({id:item.id,showUnLine:item.showUnLine})
                                })
                                setShowDataF(showDataF.concat(state_device.devicestate.filter(x=>x.District===m.id&&!x.Online)));
                              }
                              else{
                                showDis.map(item=>{
                                  item.id===m.id?
                                  temp.push({id:item.id,showUnLine:false})
                                  :
                                  temp.push({id:item.id,showUnLine:item.showUnLine})
                                })
                                setShowDataF(showDataF.filter(x=>(x.District===m.id&&x.Online)||x.District!==m.id));
                              }
                              setShowDis(temp)
                            }}>
                                显示全部
                            </Checkbox>
                          </div>
                        </div>
                        {/* {(showDis.filter(x=>x.id === m.id).length>0) &&<div style={{border: '1px dashed #CDCDCE',width:'100%'}}></div>} */}
                        
                        <div style={{flex: 1, display: (show===0||show===2) ? 'flex' : '', flexDirection: 'row', alignContent: 'flex-start', flexFlow: 'row wrap' ,padding:5}}>
                          {
                            showData.length > 0 ? 
                            showData.filter(x => { return x.District === m.id }).map((item,index)=>{
                              if(item.Type===209){
                                return (<Device902Item item={item} cloud={item.From === 1} show={show} key={index} visible={item.ExSensor&&item.ExSensor.param&&item.ExSensor.param.length>0} callback={(v) => {
                                  setCloseInterval(v)
                                }} />)
                              }else{
                                return (<DeviceItem item={item} cloud={item.From === 1} show={show} key={index}  callback={(v) => {
                                  setCloseInterval(v)
                                }} />)
                              }
                              
                            })
                            :
                            showDataF.filter(x => { return x.District === m.id }).map((item,index) =>{
                              if(item.Type===209){
                                return (<Device902Item item={item} cloud={item.From === 1} show={show} key={index}  visible={item.ExSensor&&item.ExSensor.param&&item.ExSensor.param.length>0}  callback={(v) => {
                                  setCloseInterval(v)
                                }} />)
                              }else{
                                return (<DeviceItem item={item} cloud={item.From === 1} show={show} key={index}  callback={(v) => {
                                  setCloseInterval(v)
                                }} />)
                              }
                            })
                          }
                        </div>
                      </div> 
                  })
                }

              </Spin>
          </div>}
        </div>
        <Modal
          title="平面图"
          style={{ top:window.innerHeight/15 }}
          visible={planeVis}
          onOk={() => setPlaneVis(false)}
          onCancel={() => setPlaneVis(false)}
          width={document.getElementById('DataView')?document.getElementById('DataView').width:1200}
          footer={null}
          // mask={false}
        >
          <Plane  />
        </Modal>
        <Modal
          title="上传追溯平台"
          visible = {showUpDataModal}
          onCancel={() => {
            setShowUpDataModal(false);
            setStartTime('');
            setEndTime('');
          }}
          width={900}
          destroyOnClose
          footer={null}
        >
          <Tabs 
            tabBarExtraContent={
              <div style={{display:isUpHuBei?'none':'block'}}>
                <Checkbox checked={upAuto} onChange={e=>{
                  if (window.CefSharp !== undefined) {
                    window.CefSharp.BindObjectAsync("bound");
                    window.bound.setUpAuto(e.target.checked);
                    setUpAuto(e.target.checked);
                  }
                }}>自动上传</Checkbox>
                        
              </div>
            }

          >
            <TabPane tab="上传" key="1">
              <div>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <div>
                      <Radio.Group onChange={e=>{
                        setSelDevType(e.target.value);
                      }} value={selDevType} options={devTypeOptions} ></Radio.Group>
                    </div>
                    <div>
                      <RangePicker
                        style={{borderRadius:10}}
                        // allowEmpty={[false,true]}
                        ranges={{
                            今天: [moment().startOf('day'), moment()],
                            '前一天': [moment().subtract(1, 'days'), moment()],
                            '前二天': [moment().subtract(2, 'days'), moment()],
                            '前三天': [moment().subtract(3, 'days'), moment()],
                            '一小时': [moment().subtract(1, 'hours'), moment()],
                            '三小时': [moment().subtract(3, 'hours'), moment()],
                            '五小时': [moment().subtract(5, 'hours'), moment()],
                            '六小时': [moment().subtract(6, 'hours'), moment()],
                        }}
                        showTime
                        format="YYYY-MM-DD HH:mm"
                        disabledDate={current=>{
                          if(dates){
                            if(dates[0] === null){
                              return current && current > moment().endOf('day')||(dates[1] && dates[1].diff(current, 'days') > 1);
                            }else{
                              const tooLate = dates[0] && current.diff(dates[0], 'days') > 2;
                            const tooEarly = dates[1] && dates[1].diff(current, 'days') > 1;
                            return current && current > moment().endOf('day') || tooLate||tooEarly;
                            }
                            
                          }else {
                            return  current && current > moment().endOf('day')
                          }
                        }}
                        onCalendarChange = {val=>{
                          setDates(val);
                        }}
                        onChange={(dates, dateStrings) => {
                          if(dates===null){
                              setStartTime('');
                              setEndTime('');
                          }else{
                              setStartTime(dateStrings[0]+':00');
                              setEndTime(dateStrings[1]+':00');
                          }     
                        }}
                        onOpenChange={open=>{
                          if(open){
                            setDates([]);
                            clearInterval(dateTimer);
                            dateTimer=setInterval(() => {
                              let elem= document.querySelector('.ant-picker-input input');
                              let elems= document.querySelectorAll('.ant-picker-input input:last-child');
                              let elem1=elems.length>1 &&elems[1];
                              let arrs=[];
                              arrs.push((elem&&elem.value!=='')?moment(elem.value,'YYYY-MM-DD HH:mm'):null);
                              arrs.push( (elem1&&elem1.value!=='')?moment (elem1.value,'YYYY-MM-DD HH:mm'):null);
                              setDates(arrs);
                            }, 1000);
                          }else{
                            clearInterval(dateTimer)
                            setDates([]);
                          }
                        }}
                      />
                    </div>
                    
                </div>
                <div style={{marginTop:10,height:1,backgroundColor:'#ccc'}}></div>
                <div style={{marginTop:10}}>
                  {
                    selDevType==1?//监控主机
                    <div className='upData'>
                      <div style={{float:'right',display:isUpHuBei?'none':'block'}}>
                        <Button type='primary' style={{borderRadius:10}} onClick={()=>{
                          if(upQYID==='')return message.error('企业ID不能为空');
                          if(upKEY==='')return message.error('企业秘钥不能为空');
                          if(startTime===''||endTime==='')return message.error('起始时间不能为空');
                          if(storeChecked.length===0)return message.error('测点不能为空');
                          http.post('/v1/hb_upload',{
                            secretkey:upKEY,
                            qyid:upQYID,
                            mode:0,
                            d_ids:storeChecked,
                            starttime:startTime,
                            endtime:endTime,
                          }).then(data=>{
                            console.log(data);
                          })
                        }}>
                          上传
                        </Button>
                        
                      </div>
                      <div style={{float:'right',display:isUpHuBei?'block':'none'}}>
                        <Checkbox checked={upAutoWare} onChange={e=>{
                          if (window.CefSharp !== undefined) {
                            if(e.target.checked){
                              if(storeChecked.length===0)return message.error('测点不能为空');
                            }
                            window.CefSharp.BindObjectAsync("bound");
                            window.bound.setUpAutoWare(e.target.checked);
                            setUpAutoWare(e.target.checked);
                            http.post('/v1/wh_upload_ware',{
                              device:storeChecked,
                              starttime:startTime,
                              endtime:endTime,
                              isauto:e.target.checked
                            }).then(data=>{
                              console.log(data);
                            })
                          }
                        }}>自动上传</Checkbox>
                        <Button type='primary' style={{borderRadius:10}} onClick={()=>{
                          if(startTime===''||endTime==='')return message.error('起始时间不能为空');
                          if(storeChecked.length===0)return message.error('测点不能为空');
                          if (window.CefSharp !== undefined) {
                            window.CefSharp.BindObjectAsync("bound");
                            window.bound.setUpAutoWare(false);
                            setUpAutoWare(false);
                          }
                          http.post('/v1/wh_upload_ware',{
                            device:storeChecked,
                            starttime:startTime,
                            endtime:endTime,
                            isauto:false
                          }).then(data=>{
                            console.log(data);
                          })
                        }}>
                          手动上传
                        </Button>
                        
                      </div>
                      <Checkbox indeterminate={storeIndeterminate} checked={storeCheckAll} onChange={e=>{
                        setStoreChecked(e.target.checked ? storeDevValues:[])
                        setStoreIndeterminate(false);
                        setStoreCheckAll(e.target.checked);
                      }}>
                          全选
                      </Checkbox>
                      <Checkbox.Group options={storeDevOptions}  value={storeChecked} onChange={list=>{
                        setStoreChecked(list)
                        setStoreIndeterminate(!!list.length && list.length < storeDevOptions.length);
                        setStoreCheckAll(list.length === storeDevOptions.length);
                      }}/>
                    </div>
                    : selDevType==2?//保温箱
                    <div className='upData'>
                      <div style={{float:'right',display:isUpHuBei?'none':'block'}}>
                        <Button type='primary' style={{borderRadius:10}} onClick={()=>{
                          if(upQYID==='')return message.error('企业ID不能为空');
                          if(upKEY==='')return message.error('企业秘钥不能为空');
                          if(startTime===''||endTime==='')return message.error('起始时间不能为空');
                          if(incChecked.length===0)return message.error('测点不能为空');
                          http.post('/v1/hb_upload',{
                            secretkey:upKEY,
                            qyid:upQYID,
                            mode:2,
                            d_ids:incChecked,
                            starttime:startTime,
                            endtime:endTime,
                          }).then(data=>{
                            console.log(data);
                          })
                        }}>
                          上传
                        </Button>
                      </div>
                      <Checkbox indeterminate={incIndeterminate} checked={incCheckAll} onChange={e=>{
                        setIncChecked(e.target.checked ? incDevValues:[])
                        setIncIndeterminate(false);
                        setIncCheckAll(e.target.checked);
                      }}>
                          全选
                      </Checkbox>
                      <Checkbox.Group options={incDevOptions} value={incChecked} onChange={list=>{
                        setIncChecked(list)
                        setIncIndeterminate(!!list.length && list.length < incDevOptions.length);
                        setIncCheckAll(list.length === incDevOptions.length);
                      }}/>
                    </div>
                    ://车载
                    <div className='upData'>
                      <div style={{float:'right',display:isUpHuBei?'none':'block'}}>
                        <Button type='primary' style={{borderRadius:10}} onClick={()=>{
                          if(upQYID==='')return message.error('企业ID不能为空');
                          if(upKEY==='')return message.error('企业秘钥不能为空');
                          if(startTime===''||endTime==='')return message.error('起始时间不能为空');
                          if(vehChecked.length===0)return message.error('测点不能为空');
                          http.post('/v1/hb_upload',{
                            secretkey:upKEY,
                            qyid:upQYID,
                            mode:1,
                            d_ids:vehChecked,
                            starttime:startTime,
                            endtime:endTime,
                          }).then(data=>{
                            console.log(data);
                          })
                        }}>
                          上传
                        </Button>
                      </div>
                      <div style={{float:'right',display:isUpHuBei?'block':'none'}}>
                        <Checkbox checked={upAutoCar} onChange={e=>{
                          if (window.CefSharp !== undefined) {
                            if(e.target.checked){
                              if(vehChecked.length===0)return message.error('测点不能为空');
                            }
                            window.CefSharp.BindObjectAsync("bound");
                            window.bound.setUpAutoCar(e.target.checked);
                            setUpAutoCar(e.target.checked);
                            http.post('/v1/wh_upload_car',{
                              device:vehChecked,
                              starttime:startTime,
                              endtime:endTime,
                              isauto:e.target.checked
                            }).then(data=>{
                              console.log(data);
                            })
                          }
                        }}>自动上传</Checkbox>
                        <Button type='primary' style={{borderRadius:10}} onClick={()=>{
                          if(startTime===''||endTime==='')return message.error('起始时间不能为空');
                          if(vehChecked.length===0)return message.error('测点不能为空');
                          if (window.CefSharp !== undefined) {
                            window.CefSharp.BindObjectAsync("bound");
                            window.bound.setUpAutoCar(false);
                            setUpAutoCar(false);
                          }
                          http.post('/v1/wh_upload_car',{
                            device:vehChecked,
                            starttime:startTime,
                            endtime:endTime,
                            isauto:false
                          }).then(data=>{
                            console.log(data);
                          })
                        }}>
                          手动上传
                        </Button>
                        
                      </div>
                      <Checkbox indeterminate={vehIndeterminate} checked={vehCheckAll} onChange={e=>{
                        setVehChecked(e.target.checked ? vehDevValues:[])
                        setVehIndeterminate(false);
                        setVehCheckAll(e.target.checked);
                      }}>
                          全选
                      </Checkbox>
                      <Checkbox.Group options={vehDevOptions} value={vehChecked} onChange={list=>{
                        setVehChecked(list)
                        setVehIndeterminate(!!list.length && list.length < vehDevOptions.length);
                        setVehCheckAll(list.length === vehDevOptions.length);
                      }}/>
                    </div>
                  }
                </div>
              </div>
            </TabPane>
            {
              (upQYID!="")&&
              <TabPane tab="管理" key="2">
                <UpDevManage></UpDevManage>
              </TabPane>
            }
          </Tabs>
          
        </Modal>
      </div>
    </>
  )
}

export default Data